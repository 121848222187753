// Author Vasilis Neris



#projects-wrapper{
  width: 55%;
  margin: auto;
  margin-bottom: 20rem;
  padding-top: 10%;
  h2{
    font-size: 10rem;
    font-weight: 100;
  }


  .project-box{
    margin-bottom: 25rem;
    position: relative;
    > div{
      @include flex(flex-start,space-between);
      width: 100%;
    }
    .image-wrapper{
      position: relative;
      img{
        position: absolute;
        left: 0;
        max-width: 700px;

      }
    }
    .small-text{
      color: color(cyan,base);
      font-size: 1.4rem;
      margin-bottom: 1rem;
      display: inline-block;
    }
    h3{
      font-size: 3rem;
      font-weight: 600;
      color: color(cyan,lighter);
      margin:0;
      margin-bottom: 1.5rem;
    }
    .content-wrapper{
      text-align: right;
      width: 100%;
      .description{
        font-size: 1.4rem;
        text-align: left;
        color: color(cyan,light);
        margin-bottom: 1rem;
        @include flex(center,flex-end);
        p{
          line-height: 20px;
          background-color: lighten(color(blue,darker), 5%);
          padding: 20px;
          position: relative;
          z-index: 10;
          width: 50%;
        }
      }
      .build-with{
        margin-bottom: 2rem;
        span{
          color: color(blue,lighter);
          font-size: 1.3rem;
          margin-right: 1rem;
          &:last-child{
            margin: 0;
          }
        }
      }
    }
    .cms-logo{
      @include flex(center,flex-end);
      color: color(cyan,light);
      font-size: 1.3rem;
      img{
        @include widthHeight(24px);
        margin-left: 1.5rem;
      }
    }
    a{
      &:before{
        display:none;
      }
      &:hover{
        svg{
          path{
            fill: color(cyan,base);
          }
        }
      }
      svg{
        @include widthHeight(20px);
        path{
          fill: color(cyan,light);
        }
      }
    }

  }
  .project-box:nth-child(even){
    > div{
      @include flex-direction(row-reverse);

    }
    .image-wrapper{
      img{
        right: 0;
        left: initial;
      }
    }
    .content-wrapper{
      text-align: left;
      .description,.cms-logo{
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  #projects-wrapper {
    width: 70%;
  }
}
@media screen and (max-width: 1280px) {
  #projects-wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 991px) {
  #projects-wrapper {

    .project-box {
      .image-wrapper{
        img{
          object-fit: contain;
          height: 200px;
          position:initial;
        }
      }
      .content-wrapper .description p{
        width: 85%;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  #projects-wrapper {

    .project-box {
      .image-wrapper{
        width:100%;
        height: 365px;
        img{
          @include widthHeight(100%);
          object-fit: cover;
        }
      }
      .content-wrapper{
        @include widthHeight(100%);
        padding: 45px 15px;
        right: 0;
        position: absolute;
        background-color: transparentize(color(blue,darker), .2);
        .description{
          p{
            background-color:transparent;
            padding: 0;
            text-align: right;
          }
        }
      }
    }
    .project-box:nth-child(even){
      .content-wrapper{
        left: 0;
        right: initial;
        .description {
          p {
            text-align: left;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #projects-wrapper {
    .project-box {
      margin-bottom: 5rem;

    }
  }
}