// Author Vasilis Neris



#intro{
  position: relative;
  width: 60%;
  margin: auto;
  margin-top: 20rem;
  height: 80vh;
  .small-text{
    margin-bottom: 2rem;
    display:inline-block;
    color: color(cyan,base);
    font-size: 1.6rem;
    font-weight: 600;
  }
  h1{
    font-size: $intro__name__size--xl;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 2rem;
    color:color(white,base);

  }
  h2{
    font-size: $intro__name__size--xl - 4;
    font-weight: 600;
    color:color(cyan,darker);

    margin-top: .5rem;
  }
  .description{
    width:50%;
    z-index: 100;
  }
  p{
    font-size: 2rem;
    color: color(cyan,light);
    width: 60%;
  }
  a{
    border: 1px solid color(cyan,base);
    padding: 8px 20px;
    color: color(cyan,base);
    font-size: 1.4rem;
    line-height: normal;
    margin-right: 2rem;
    &:hover{
      background-color: color(cyan,base);
      color:color(blue,darker);
    }
  }
}

@media screen and (max-width: 1280px) {
  #intro{
    width: 80%;
    p{
      width: 100%;
    }
  }
}


@media screen and (max-width: 768px) {
  #intro{
    margin-top: 15%;
    height: initial;
    margin-bottom: 15rem;
    .small-text{
      font-size: 1.4rem;
    }
    h1{
      font-size: $intro__name__size--xl - 4.5;

    }
    h2{
      font-size: $intro__name__size--xl - 6;

    }

    p{
      font-size: 1.4rem;
      color: color(cyan,light);
      width: 100%;
      margin-bottom:2rem;
    }
  }
}

@media screen and (max-width: 500px) {
  #intro{
    margin-top: 25%;
    height: initial;
    margin-bottom: 15rem;
    width: 85%;
  }
}