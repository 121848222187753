// Author Vasilis Neris



#skills-wrapper{
  width: 60%;
  margin: auto;
  height: 70vh;
  margin-bottom: 25rem;
  padding-top: 10%;

  h2{
    font-size: 10rem;
    font-weight: 100;
  }

  .skill-box{
    @include flex(center,space-around);
    .info-wrapper{
      width: 50%;
      padding: 0 25px;

    }
    h3{
      font-size: 2rem;
      color: color(cyan,base);
    }
    p{
      font-size: 1.4rem;
      color: color(cyan,light);
    }
    .image-wrapper{
      text-align: center;
      width: 50%;
    }
    img{
      @include widthHeight(65%);
      object-fit: contain;
      margin: auto;
      will-change: opacity, transform;

    }
  }
.BrainhubCarouselItem--active{
  .image-wrapper{
    img{
      animation: slide-in-blurred-top .8s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      will-change: opacity, transform;
    }
  }
}
  .BrainhubCarousel__arrows{
    background-color: lighten(color(blue,darker), 5%);
    &:hover{
      background-color: color(blue,darker);
      span{
       border-color: color(cyan,base);
      }
    }
    span{
      border-color: color(cyan,light);
    }
  }


}

@media screen and (max-width: 1280px) {

  #skills-wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 991px) {

  #skills-wrapper {
    width: 85%;
  }
}

@media screen and (max-width: 768px) {

  #skills-wrapper {
    .skill-box{
      position: relative;
      .image-wrapper{
        width: 100%;
        height: 250px;
        img{
          @include widthHeight(100%);
          object-fit: contain;
        }
      }
      .info-wrapper{
        position: absolute;
        background-color: transparentize(color(blue,darker), .2);
        @include widthHeight(100%);
        @include flex(center,center,column);
        p{
          width: 80%;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  #skills-wrapper {
    .BrainhubCarousel__arrows{
      padding: 15px;
      span{
        padding: 3px;
        border-width: 2px 2px 0 0;
      }
    }
    .skill-box{
      p{
        font-size: 1.2rem;
      }
    }

  }
}