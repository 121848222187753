// Author Vasilis Neris



div#contact-wrapper {
  width: 100%;
  text-align: center;
  height: 70vh;
  h3{
    color: color(white,base);
    font-size: 4rem;
    font-weight: bold;
    span{
      color: color(cyan,base);
    }
  }
p{
  color:color(cyan,light);
  font-size: 2rem;
}
  a{
    color: color(cyan,base);
    font-size: 2rem;
    border: 1px solid color(cyan,base);
    padding: 8px 20px;
    &:hover{
      background-color: color(cyan,base);
      color: color(blue,darker);
    }
  }
  .socials{
    @include flex(center,center);
    margin-top: 3rem;
    a{
      border: 0;
      &:before{
        display: none;
      }
      &:hover{
        background-color: transparent;
        svg{
          path{
            fill: color(cyan,base);
          }
        }
      }
    }
    svg{
      @include widthHeight(25px);
      path{
        fill: color(cyan,light);
      }
    }
  }

}

@media screen and (max-width: 768px) {
  div#contact-wrapper {
    h3{
      font-size: 3rem;

    }
    p{
      font-size: 1.4rem;
      margin-bottom: 3rem;
    }
  }

}