// Author Vasilis Neris



div#about-wrapper {
  width: 60%;
  margin: auto;
  height: 100vh;
  padding-top: 10%;
  p{
    font-size: 2rem;
    width: 91%;

    color: color(cyan,light);
  }
  .content-wrapper{
    @include flex(flex-start);
    .content{
      margin-right: 2rem;
    }
    .image-wrapper{
      position: relative;
      &:hover{
        &:after{
          border-color: color(cyan,base);
          transition: all .3s ease;
          top:-15px;
          right: -15px;
        }
        img{
          filter: grayscale(0);
          transition: all .3s ease;
        }

      }
      &:after{
        content: '';
        border: 1px solid color(cyan,darker);
        top: -20px;
        right: -20px;
        z-index: -1;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        transition: all .3s ease;

      }
    }
    img{
      @include widthHeight(350px);
      object-fit: cover;
      filter: grayscale(100%);
      transition: all .3s ease;


    }
  }
}

@media screen and (max-width: 1280px) {
  div#about-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 991px) {
  div#about-wrapper {
    width: 85%;
    .content-wrapper {
      img{
        @include widthHeight(250px);
      }
      p{
        font-size: 1.6rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  div#about-wrapper {
    .content-wrapper {
      flex-direction: column;
      .image-wrapper{
      margin-top: 4rem;
        //@include flex(center,center);
      }
      img{
        @include widthHeight(250px);
      }
      p{
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  div#about-wrapper {
    .content-wrapper {
      img{
        @include widthHeight(200px);
      }
      p{
        font-size: 1.3rem;
        width: 100%;
      }
    }
  }
}