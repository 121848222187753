// Author Vasilis Neris



body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x:hidden;
  background-color: color(blue,darker);
  @include scrollbars(5px, color(cyan,dark));
}


.page-wrapper{
  margin:auto;
}

#title-wrapper{
  margin-bottom: 10rem;
  h1,h2,h3{
    color: color(white,base);
    font-weight: 600;
    font-size: 2.4rem;
    position: relative;
    &:after{
      content:'';
      height:1px;
      background-color:color(cyan,darker);
      bottom:7px;
      left: 25px;
      max-width: 300px;
      width: 100%;
      position: relative;
      display: inline-block;
    }
    span{
      color: color(cyan,base);
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  #title-wrapper{
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 500px) {
  #title-wrapper{
    margin-bottom: 2rem;
    h3{
      &:after{
        display:none;
      }
    }
  }
}