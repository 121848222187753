// Author Vasilis Neris



#navigation{
  position:sticky;
  top:0;
  z-index: 100;
  background-color: color(blue,darker);
  .header{
    height:100%;
    padding: 0 25px;
    @include flex(center,space-between);
    .logo{
      flex: 1;
      font-size: 2rem;
      color: color(cyan,base);
      span{
        @include widthHeight(35px);
        border: 2px solid color(cyan,base);
        border-radius: 50%;
        font-size: 1.4rem;
        font-weight: bold;
        @include flex(center,center);
      }

    }
  }
  svg{
    @include widthHeight(40px);
    display: none;
    path{
      fill: color(cyan,base);
    }
  }
  .nav-items-wrapper{
    @include flex(center);
    .menu-toggler{
      font-size: 2rem;
      color: color(white,base);
      border-radius: 50%;
      border: 2px solid color(cyan,base);
      @include widthHeight(30px);
      margin-top: 10rem;
      display:none;
    }
  }
  .sidenav-item{
    a{
      font-size: $header__item__size--xl;
      text-transform: uppercase;
      padding: 2rem 5rem;
      font-weight: 600;
      color: color(cyan,lighter);
      &:before{
        background-color:   color(cyan,base);
      }
      span{
        color: color(cyan,base);
      }
    }
  }
}

@media screen and (max-width: 991px){
  #navigation{
    padding: 1rem 0;
    svg{
      display: block;
    }
    .nav-items-wrapper{
      position: fixed;
      @include widthHeight(100%);
      top:0;
      right: -100%;
      background-color: color(blue,darker);
      flex-direction: column;
      justify-content: center;
      transition: all .3s ease;
      .menu-toggler{
        @include flex(center,center);
      }

      &.active{
        right: 0;
        transition: all .3s ease;
      }
    }
  }

}