// Author Vasilis Neris






html{
  font-size: 62.5%;
}

body{
  div,a,p,h1,h2,h3,h4,h5,h6,li{
    font-family: $main_font_family;
  }
}


a{
  color: $anchor_color;
  @include animatedUnderline;
  text-decoration: none;
}