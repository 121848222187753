// Author: Vasilis Neris
//============================

/*
Example Call for color function

.footer{
  background-color: color(black,base);
}
color = function call
black = key of the palette
base  = value in the black key (in this case its #000)

// outputs
.footer{
  background-color: #000;
}

 */
@function color($color,$tone){
  @return map-get(map-get($palette, $color), $tone);
}


//
// Theme Colors
//============================
$palette: (
        red: (
                base:    #ff0000,
                dark:    #b22222,
                darker:  #8b0000,
                light:   #cd5c5c,
                lighter: #f08080
        ),
        orange: (
                base:    #ffa500,
                dark:    #FF8C00,
                light:   #FF7F50,
        ),
        yellow: (
                base:    #ffff00,
                dark:    #ffd700,
                light:   #FF7F50,
        ),
        green: (
                base:    #008000,
                dark:    #006400,
                light:   #228B22,
        ),
        purple: (
                base:    #800080,
                dark:    #663399,
                darker:  #4B0082,
                light:   #9932CC,
                lighter: #9400D3

        ),
        pink: (
                base:    #ff69b4,
                dark:    #FF1493,
                darker:  #C71585,
                light:   #FFB6C1,
                lighter: #FFC0CB
        ),
        cyan: (
                base:    #64ffda,
                dark:    #00CED1,
                darker:  #5F9EA0,
                light:   #AFEEEE,
                lighter: #E0FFFF,
        ),
        blue: (
                base:    #0000ff,
                dark:    #0000CD,
                darker:  #0a192f,
                light:   #4169E1,
                lighter: #6c7794,
                facebook: #3b579d,
                twitter: #2ca9e1
        ),
        brown: (
                base:    #8b4513,
                dark:    #A0522D,
                light:   #d2691e
        ),
        gray: (
                base:    #808080,
                dark:    #696969,
                light:   #a9a9a9,
                lighter: #c0c0c0,

        ),
        white: (
                base:    #fff,
                light:   #fffafa,
                lighter: #ebebeb
        ),
        black: (
                base:    #000,
                light:   #282c34,
                lighter: #333

        ),

);





